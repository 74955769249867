import { type MouseEvent, useState, useContext } from 'react'

import { type SanityGiftCardFormModule } from '@data/sanity/queries/types/modules'
import { SiteContext } from '@lib/site-context'

import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import SimplePortableText from '@components/simple-portable-text'
import Logo from '@components/logo'
import IconButton from '@components/buttons/icon-button'
import ButtonLink from '@components/buttons/button-link'

type GiftCardFormProps = Pick<
  SanityGiftCardFormModule,
  | 'heading'
  | 'text'
  | 'values'
  | 'defaultValue'
  | 'minusButtonLabel'
  | 'plusButtonLabel'
  | 'submitButtonLabel'
>

const GiftCardForm = ({
  heading,
  text,
  values,
  defaultValue,
  minusButtonLabel,
  plusButtonLabel,
  submitButtonLabel,
}: GiftCardFormProps) => {
  const { settings } = useContext(SiteContext)

  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    defaultValue ?? values?.[0]
  )

  if (!values || values.length === 0) {
    return null
  }

  const handleMinusClick = (event: MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    const defaultIndex = defaultValue ? values.indexOf(defaultValue) : 0
    const currentIndex = selectedValue
      ? values.indexOf(selectedValue)
      : defaultIndex
    const newIndex = Math.max(currentIndex - 1, 0)

    setSelectedValue(values[newIndex])
  }

  const handlePlusClick = (event: MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    const defaultIndex = defaultValue ? values.indexOf(defaultValue) : 0
    const currentIndex = selectedValue
      ? values.indexOf(selectedValue)
      : defaultIndex
    const newIndex = Math.min(currentIndex + 1, values.length - 1)

    setSelectedValue(values[newIndex])
  }

  return (
    <section className="overflow-hidden bg-brown text-orange">
      <div className="relative container flex flex-col md:grid md:grid-cols-2 gap-12 py-12 md:py-44 xl:py-72">
        <div className="space-y-7 text-center order-2 md:order-none">
          <div className="space-y-5">
            {heading && <h2 className="">{heading}</h2>}
            {text && <SimplePortableText content={text} />}
          </div>

          <div className="inline-flex rounded-full justify-center bg-red gap-4 bg-opacity-50 p-2">
            <div className="inline-flex items-center gap-x-4 py-2 px-3">
              <IconButton
                variant={ButtonVariant.OUTLINED}
                color={ButtonColor.WHITE}
                size={ButtonSize.SMALL}
                icon="Minus"
                aria-label={minusButtonLabel}
                onClick={handleMinusClick}
              />

              <div className="is-h4">{selectedValue},-</div>

              <IconButton
                variant={ButtonVariant.OUTLINED}
                color={ButtonColor.WHITE}
                size={ButtonSize.SMALL}
                icon="Plus"
                aria-label={plusButtonLabel}
                onClick={handlePlusClick}
              />
            </div>

            {!!settings?.lifePeaksAccountName && (
              <ButtonLink
                variant={ButtonVariant.FILLED}
                href={`https://order.lifepeaks.dk/${settings.lifePeaksAccountName}?amount=${selectedValue}#value`}
                className="text-[15px]"
              >
                {submitButtonLabel}
              </ButtonLink>
            )}
          </div>
        </div>

        <Logo
          className="text-gray max-w-[280px] mx-auto md:max-w-none md:absolute md:bottom-0 md:translate-y-[40%] md:-right-1/3 md:w-3/4"
          type="symbol"
        />
      </div>
    </section>
  )
}

export default GiftCardForm
